<template>
  <div />
</template>
<script>
import { SystemVersion } from '@/utils/public'

export default {
  name: 'JumpLink',
  data() {
    return {}
  },
  mounted() {
    if (SystemVersion() === 'isIOS') {
      window.location.href =
        'https://apps.apple.com/cn/app/%E6%82%A6%E6%97%85%E4%BC%9A/id1460325408'
    } else if (SystemVersion() === 'isAndroid') {
      window.location.href =
        'https://a.app.qq.com/o/simple.jsp?pkgname=com.newHuanQiuYueLv.www&from=singlemessage'
    } else {
      this.$router.push('/')
    }
  },
  methods: {}
}
</script>
<style lang="less" scoped>
</style>
